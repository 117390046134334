import React, {useEffect, useState, useRef} from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useNavigate, useLocation,Link } from 'react-router-dom';
import Request from './Request';
import ImageCarousel from "./ImageCarousel";
import ModalRequest from './ModalRequest'
import '../App.css'; 
import monitoreo1 from '../assets/pagina web 2/Monitoreo 1.jpg'
import industrias from '../assets/industrias.jpg'
import barrio from '../assets/barrio cerrado.jpg'
import comercio from '../assets/service-02.jpg'
import videoBackground from '../assets/Video-de-Lucas-Armat-Mkt.mp4';
import seguridad from '../assets/logo-seguridad.png'
import medida from '../assets/logo-medida.png'
import a from '../assets/logo-a.png'
import tu from '../assets/logo-tu.png'
import seguri from '../assets/logo-seguri.png'
import dad from '../assets/logo-dad.png'
import medi from '../assets/logo-medi.png'
import da from '../assets/logo-da.png'
import QuienesCarousel from './QuienesCarousel'
import polo from '../assets/TECHNOPOLO.jpg';
import blipack from '../assets/BLIPACK-300x60.png'
import aerosoles from '../assets/logo-AEROSOLES.png'
import consorcio1 from '../assets/admin consrocio 1.jpeg'
import consorcio2 from '../assets/admin consorcio 2.jpeg'
import { HashLink } from 'react-router-hash-link';


const Inicio = () => {

  const IconoFlecha = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className="icono-flecha"
      >
      <path d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"></path>
    </svg>
  );

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -100; 
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  };

  const tarjetas = [
    { id: 'industrias', src: industrias, title: 'VIGILANCIA EN INDUSTRIAS' },
    { id: 'barrio-cerrado', src: barrio, title: 'VIGILANCIA EN BARRIOS CERRADOS' },
    { id: 'comercios', src: comercio, title: 'VIGILANCIA EN COMERCIOS' },
  ];
  

  //////////////////////////////////////
  const images = [
    { id: 4, src:consorcio1, alt: "Imagen 4" },
    { id: 2, src:blipack, alt: "Imagen 2" },
    { id: 1, src:consorcio2, alt: "Imagen 1" },
    { id: 5, src:polo, alt: "Imagen 5" },
    { id: 3, src:aerosoles, alt: "Imagen 3" },
  ];

  useEffect(() => {
    if (window.location.hash) {
      const hash = window.location.hash;
      const targetElement = document.querySelector(hash);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);


  return (
      <>
     <Container fluid className="security-section overlay2">
  <video autoPlay loop muted playsInline className="background-video">
    <source src={videoBackground} type="video/mp4" />
  </video>

  <Row className="align-items-center px-3 py-5 justify-content-center">
    <Col md={12} lg={7} xs={12} className="text-content text-lg-start text-sm-center">
      <div className="d-flex flex-wrap justify-content-lg-start mb-4 justify-content-sm-center aling-items-center boxs">
        <img src={seguridad} alt="" className="logo-inicio mx-2 box" />
        <img src={a} alt="" className="logo-inicio mx-2 box" />
        <img src={tu} alt="" className="logo-inicio mx-2 box" />
        <img src={medida} alt="" className="logo-inicio mx-2 box" />
      </div>
      <h3>Cuando se trata de fiabilidad, somos lo que necesitás!</h3>
      <p className="text-white">ARMAT 03 – SERVICIOS DE SEGURIDAD PRIVADA</p>
    </Col>

    <Col md={12} lg={5} xs={12} className="form-content text-center justify-content-center">
    <div className=''>
      <h2 className="text-white">ENVIANOS TU CONSULTA</h2>
      <h5 className="text-white mb-4">TE RESPONDEMOS A LA BREVEDAD</h5>
      <Request />
      </div>
    </Col>
  </Row>
</Container>

       
      <Container fluid className="nosotros-section" >
        <Row className="justify-content-center text-center py-5" style={{ backgroundColor: '#101710', color: '#FFFFFF' }}>
          <Col md={6}>
            <h3 className='text-white'>
              En ARMAT 03 prestamos servicios para la protección de personas y bienes en todos los entornos. Nuestra amplia experiencia, funcionamiento seguro y respuesta integral están garantizados por un equipo de profesionales comprometidos con la innovación y la calidad a todos los niveles.
            </h3>
          </Col>        
        </Row>
      
      <Container>
        <hr className='text-center divider-inicio'/> 
      </Container>

        <Row className="align-items-center justify-content-center mb-5 py-5"> 
          <Col lg={5} md={5}  xs={12} sm={12} className="d-flex justify-content-center">
            <img src={monitoreo1} alt="Monitoreo" className="img-fluid" style={{ maxHeight: '650px', objectFit: 'cover' }} />
          </Col>
          <Col lg={7} md={7} xs={12} sm={12} className="mt-3 text-center text-md-start">
            <h2 className='text-white pb-3'>Aspiramos a la excelencia en nuestro trabajo</h2>
            <ul className='pb-2'> 
              <li className='text-white items-aspiramos'><IconoFlecha/> Soluciones a la medida de cada cliente</li>
              <li className='text-white items-aspiramos'><IconoFlecha/>Supervisión remota y presencial</li>
              <li className='text-white items-aspiramos'><IconoFlecha/>Prestamos servicios inmediatamente</li>
              <ModalRequest/>
            </ul>
          </Col>
        </Row>

        <Container className="pb-5" >
          <Row className="g-4 justify-content-center">
            {tarjetas.map((card, index) => (
              <Col key={index} md={4} className="d-flex justify-content-center">
                <Card className="text-white " style={{ maxWidth: '350px', minHeight: '250px' }}>
                  <Card.Img src={card.src} alt={card.title} className="" />
                  <Card.ImgOverlay className="d-flex flex-column justify-content-center align-items-center">
                    <Card.Title className="mb-3 text-center">{card.title}</Card.Title>
                    <HashLink to={`/servicios#${card.id}`} className="btn btn-success" scroll={scrollWithOffset} >
                      VER MÁS</HashLink>
                  </Card.ImgOverlay>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>   
     
    </Container>   
    <Container fluid style={{ backgroundColor: 'white' }}>
      <h1 className="d-flex justify-content-center text-black pt-5 pb-3">
        NUESTROS CLIENTES
      </h1>
      <div  className="text-center container">
        <ImageCarousel/>
      </div>
    </Container>
        



          
    </>
  );
};

export default Inicio;
